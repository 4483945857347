package three.two.bit.client.util

import kotlinx.browser.window

actual object NavigationUtil {

    actual fun navigateToUrl(url: String, target: String): Boolean {
        window.location.href = url
        return false
    }

}
