package three.two.bit.web

import io.github.aakira.napier.DebugAntilog
import js.Env
import kotlinx.js.import
import l
import org.jetbrains.compose.web.renderComposableInBody
import org.w3c.dom.css.CSS
import three.two.bit.client.Global
import three.two.bit.web.components.Body

fun main() {
    import<CSS>("./styles.scss")
    import<CSS>("./page-styles.css")

    Global.debug = true
    renderComposableInBody {
        console.log("Root on ${Env.SERVER_URL} in mode ${Env.MODE}")
        l.base(DebugAntilog())
        l.i { "Root on ${Env.SERVER_URL} in mode ${Env.MODE}" }
        Body()
    }
}
