package three.two.bit.client.component

import com.arkivanov.decompose.ComponentContext
import com.arkivanov.decompose.value.MutableValue
import com.arkivanov.decompose.value.Value
import org.kodein.di.DI
import org.kodein.di.DIAware
import org.kodein.di.instance
import three.two.bit.client.component.IMenu.Link
import three.two.bit.client.component.IMenu.Link.PageLink
import three.two.bit.client.component.IMenu.Model
import three.two.bit.client.router.IRootRouter
import three.two.bit.client.util.NavigationUtil
import three.two.bit.shared.model.NavigationConfigList

class Menu(
    override val di: DI,
    componentContext: ComponentContext,
    key: String,
) : DIAware, IMenu, ComponentContext by componentContext {

    private val rootRooter by instance<IRootRouter>()
    private val navigationConfigList by instance<NavigationConfigList>()
    private val navigationList = navigationConfigList[key]
        ?: throw IllegalStateException("Menu for key:$key is not defined in navigation")


    override val models: MutableValue<Model> = MutableValue(Model(generateMenu()))

    private fun generateMenu() = navigationList.map { navItem ->
        val page = Page.pages.firstOrNull { navItem.path == it.path }
        when {
            page != null -> PageLink(navItem.label, page)
            else -> Link.HttpLink(navItem.label, navItem.path, navItem.target)
        }
    }

    override fun navigate(link: Link) {
        when (link) {
            is Link.HttpLink -> NavigationUtil.navigateToUrl(link.path, link.target)
            is PageLink -> rootRooter.navigateToPage(link.page)
        }
    }
}

interface IMenu : DIAware {

    val models: Value<Model>

    data class Model(
        val navItems: List<Link> = emptyList(),
    )

    sealed class Link {
        abstract val label: String

        data class HttpLink(override val label: String, val path: String, val target: String = "_self") : Link()
        data class PageLink(override val label: String, val page: Page) : Link()
    }

    fun navigate(link: Link)
}
