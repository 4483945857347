package three.two.bit.web.components.views

import androidx.compose.runtime.Composable
import org.jetbrains.compose.web.dom.Div
import org.jetbrains.compose.web.dom.H1
import org.jetbrains.compose.web.dom.Text
import three.two.bit.client.annotation.KotlinDsl
import three.two.bit.client.component.ILandingScreen
import three.two.bit.client.component.ILoginForm
import three.two.bit.client.component.IRegistrationForm
import three.two.bit.client.component.IRoot
import three.two.bit.client.component.IUnknown
import three.two.bit.client.component.IUserScreen
import three.two.bit.web.components.ui.css.Bootstrap.alignItemsCenter
import three.two.bit.web.components.ui.css.Bootstrap.col5
import three.two.bit.web.components.ui.css.Bootstrap.row
import three.two.bit.web.decompose.Children

@KotlinDsl
@Composable
fun RootUI(root: IRoot) {
    l.d { "RootUI()" }

    HeaderUI(root.header)

    Children(routerStateValue = root.childStack) {
        when (val child = it.instance.component) {
            is ILoginForm -> LoginFormUI(child)
            is IRegistrationForm -> RegistrationFormUI(child)
            is ILandingScreen -> LandingScreenUI(child)
            is IUserScreen -> UserScreenUI(child)
            is IUnknown -> {
                Div(attrs = { classes(col5, row, alignItemsCenter) }) {
                    H1 { Text("Page not found") }
                }
            }
        }
    }

    FooterUI(root.footer)
}
