package three.two.bit.web.components.forms


import androidx.compose.runtime.*
import dev.petuska.kmdc.textfield.MDCTextField
import dev.petuska.kmdc.textfield.MDCTextFieldType
import kotlinx.browser.document
import org.jetbrains.compose.web.attributes.builders.InputAttrsScope
import org.jetbrains.compose.web.attributes.required
import org.jetbrains.compose.web.css.*
import org.jetbrains.compose.web.dom.Div
import org.w3c.dom.HTMLInputElement
import three.two.bit.client.Global
import three.two.bit.client.annotation.KotlinDsl
import three.two.bit.client.ui.forms.Field
import three.two.bit.client.ui.forms.FormState
import three.two.bit.client.ui.forms.Required


@KotlinDsl
@Composable
fun FormView(state: FormState, fields: List<Field>) {
    state.fields = fields

    Div {
        fields.forEach { field ->
            InputRow(
                name = field.name,
                value = field.fieldText,
                error = field.fieldError,
                isRequired = field.validators.any { it is Required } && state.wasValidating,
                label = field.fieldLabel,
                type = field.inputType.name,
                onValueChange = { value ->
                    field.fieldText = value
                    field.hideError()
                },
            )
        }
    }
}

@KotlinDsl
@Composable
fun InputRow(
    name: String,
    value: String = "",
    error: String? = null,
    isRequired: Boolean = false,
    label: String,
    type: String,
    onValueChange: (String) -> Unit = {},
    attrs: (InputAttrsScope<String>.() -> Unit)? = null,
) {
    var text by remember { mutableStateOf(value) }
    val id = "field-$name"
    Div({
        id("row-$name")
        classes("w-100")
        style {
            display(DisplayStyle.InlineBlock)
            paddingTop(2.px)
            paddingBottom(2.px)
        }
    }) {
        MDCTextField(
            text,
            type = MDCTextFieldType.Outlined,
            label = label,
            attrs = {
                classes("w-100")
                onInput {
                    text = it.value
                    onValueChange(text)
                }
                id(id)
                attrs?.invoke(this)
                style {
                    minWidth(300.px)
                }
                if (isRequired) {
                    required(true)
                }
            }
        )

        SideEffect {
            val field = document.querySelector("#$id") as HTMLInputElement

            field.type = type

            if (error != null) {
                console.log("Error: $error")
                field.setCustomValidity(label)
            } else {
                field.setCustomValidity("")
            }
            // https://developer.mozilla.org/en-US/docs/Learn/Forms/Form_validation

            if (text.isNotBlank() && field.value != text) {
                onValueChange(text)
            }
        }
    }

}