package three.two.bit.web.history

import history.createBrowserHistory
import history.createHashHistory
import kotlinx.browser.window


fun createHistory(useHash: Boolean) = if (useHash) {
    createHashHistory() to window.location.hash
} else {
    createBrowserHistory() to window.location.pathname
}
