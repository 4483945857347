package three.two.bit.web.components.views

import androidx.compose.runtime.Composable
import androidx.compose.runtime.getValue
import androidx.compose.runtime.mutableStateOf
import androidx.compose.runtime.remember
import dev.petuska.kmdc.button.MDCButton
import dev.petuska.kmdc.button.MDCButtonType
import dev.petuska.kmdc.card.MDCCard
import dev.petuska.kmdc.layout.grid.MDCLayoutGrid
import org.jetbrains.compose.web.css.AlignContent
import org.jetbrains.compose.web.css.alignContent
import org.jetbrains.compose.web.css.margin
import org.jetbrains.compose.web.css.padding
import org.jetbrains.compose.web.css.px
import org.jetbrains.compose.web.css.textAlign
import org.jetbrains.compose.web.dom.Div
import org.jetbrains.compose.web.dom.Form
import three.two.bit.client.annotation.KotlinDsl
import three.two.bit.client.component.IRegistrationForm
import three.two.bit.client.component.IRegistrationForm.Model
import three.two.bit.client.ui.forms.*
import three.two.bit.web.components.forms.FormView
import three.two.bit.web.components.ui.DivideLayout
import three.two.bit.web.components.utils.subscribeAsState

@KotlinDsl
@Composable
fun RegistrationFormUI(registrationForm: IRegistrationForm) {
    val models by registrationForm.models.subscribeAsState()
    val state by remember { mutableStateOf(FormState()) }

    DivideLayout("images/login.jpg") {
        MDCLayoutGrid {
            Form(attrs = {
                this.addEventListener("submit") {
                    if (state.validate()) {
                        registrationForm.onSubmitClicked(state.getData())
                    }
                    it.preventDefault()
                }
            }) {
                MDCCard(
                    attrs = {
                        style {
                            padding(20.px)
                            textAlign("center")
                            alignContent(AlignContent.Center)
                        }
                    }
                ) {
                    FormView(
                        state = state,
                        fields = listOf(
                            Field(
                                name = Model::firstName.name,
                                value = models.firstName,
                                label = "First name",
                                inputType = Field.InputType.Name,
                                validators = listOf(Required()),
                            ),
                            Field(
                                name = Model::lastName.name,
                                value = models.lastName,
                                label = "Last name",
                                inputType = Field.InputType.Surname,
                                validators = listOf(Required()),
                            ),
                            Field(
                                name = Model::email.name,
                                value = models.email,
                                label = "Email",
                                inputType = Field.InputType.Email,
                                validators = listOf(Required(), Email(), EmailAlreadyExist(models.emailAlreadyExist)),
                            ),
                            Field(
                                name = Model::password.name,
                                label = "Password",
                                inputType = Field.InputType.Password,
                                validators = listOf(Required()),
                            ),
                            Field(
                                name = Model::confirmPassword.name,
                                label = "Confirm password",
                                inputType = Field.InputType.Password,
                                validators = listOf(Required()),
                            ),
                            Field(
                                name = Model::street.name,
                                label = "Street",
                                inputType = Field.InputType.Street,
                                validators = listOf(Required()),
                            ),
                            Field(
                                name = Model::city.name,
                                label = "city",
                                inputType = Field.InputType.City,
                                validators = listOf(Required()),
                            ),
                            Field(
                                name = Model::country.name,
                                label = "country",
                                inputType = Field.InputType.Country,
                                validators = listOf(Required()),
                            ),
                            Field(
                                name = Model::zip.name,
                                label = "zip",
                                inputType = Field.InputType.Zip,
                                validators = listOf(Required()),
                            ),
                        )
                    )
                    Div {
                        MDCButton(
                            text = "Register!",
                            type = MDCButtonType.Outlined,
                            attrs = {
                                style {
                                    margin(3.px)
                                }
                            }
                        )
                    }
                }
            }
        }
    }
    if(models.emailAlreadyExist != null) {
        state.validateRequest(models.requestID)
    }
}
