package three.two.bit.client.manager

import kotlinx.coroutines.CoroutineScope
import kotlinx.coroutines.channels.Channel
import kotlinx.coroutines.channels.ReceiveChannel
import kotlinx.coroutines.launch
import l
import org.kodein.di.DI
import org.kodein.di.DIAware
import org.kodein.di.instance
import org.kodein.di.instanceOrNull
import three.two.bit.client.repository.UserRemoteRepository
import three.two.bit.shared.model.Address
import three.two.bit.shared.model.User

class UserManagerImpl(override val di: DI) : UserManager, DIAware, UserRemoteRepository {

    private val userRemoteRepository by instance<UserRemoteRepository>()
    private val scope by instance<CoroutineScope>()

    override val userChannel: Channel<User?> = Channel()
    override var user: User? = null
        set(value) {
            scope.launch { userChannel.send(value) }
            field = value
        }

    init {
        // User which was defined on page load
        val preloadedUser by di.instanceOrNull<User>()
        user = preloadedUser
        l.d { "Preloaded user $preloadedUser" }
    }

    override suspend fun checkIdentity(): User =
        userRemoteRepository.checkIdentity()

    override suspend fun loginUser(email: String, password: String): User =
        userRemoteRepository.loginUser(email, password).also { this.user = it }

    override suspend fun registerUser(user: User, address: Address, password: String): User =
        userRemoteRepository.registerUser(user, address, password).also { this.user = it }

    override suspend fun logout() {
        userRemoteRepository.logout()
        user = null
        l.d { "logout user = $user" }
    }

    override fun logoutUser() {
        scope.launch { logout() }
    }
}


interface UserManager: UserRemoteRepository {
    val user: User?
    val userChannel: ReceiveChannel<User?>

    fun logoutUser()
}