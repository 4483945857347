package three.two.bit.web.decompose

import androidx.compose.runtime.Composable
import androidx.compose.runtime.DisposableEffect
import androidx.compose.runtime.remember
import com.arkivanov.decompose.Child
import com.arkivanov.decompose.router.stack.ChildStack
import com.arkivanov.decompose.value.Value
import org.jetbrains.compose.web.dom.Div
import three.two.bit.web.decompose.saveable.SaveableStateHolder
import three.two.bit.web.components.utils.subscribeAsState
import three.two.bit.web.decompose.saveable.rememberSaveableStateHolder

typealias ChildContent<C, T> = @Composable (child: Child.Created<C, T>) -> Unit

typealias ChildAnimation<C, T> = @Composable (ChildStack<C, T>, ChildContent<C, T>) -> Unit
internal fun <C : Any, T : Any> emptyChildAnimation(): ChildAnimation<C, T> =
    { routerState, childContent ->
        Div(
            attrs = {classes("container-fluid", "full-height") }
        ) {
            childContent(routerState.active)
        }
    }


@Composable
fun <C : Any, T : Any> Children(
    routerState: ChildStack<C, T>,
    animation: ChildAnimation<C, T> = emptyChildAnimation(),
    content: ChildContent<C, T>
) {
    val holder = rememberSaveableStateHolder()

    holder.retainStates(routerState.getConfigurations())

    animation(routerState) { child ->
        holder.SaveableStateProvider(child.configuration.key()) {
            content(child)
        }
    }
}

@Composable
fun <C : Any, T : Any> Children(
    routerStateValue: Value<ChildStack<C, T>>,
    animation: ChildAnimation<C, T> = emptyChildAnimation(),
    content: ChildContent<C, T>
) {
    val state = routerStateValue.subscribeAsState()

    state.value.key()
    Children(
        routerState = state.value,
        animation = animation,
        content = content
    )
}

private fun ChildStack<*, *>.getConfigurations(): Set<String> {
    return backStack.map { it.configuration.key() }.toMutableSet() + active.configuration.key()
}

private fun Any.key(): String = "${this::class.simpleName}_${hashCode().toString(radix = 36)}"

@Composable
private fun SaveableStateHolder.retainStates(currentKeys: Set<Any>) {
    val keys = remember(this) { Keys(currentKeys) }

    DisposableEffect(this, currentKeys) {
        keys.set.forEach {
            if (it !in currentKeys) {
                removeState(it)
            }
        }

        keys.set = currentKeys

        onDispose {}
    }
}

private class Keys(
    var set: Set<Any>
)
