package three.two.bit.shared.model

import kotlinx.serialization.Serializable

typealias NavigationConfigList = Map<String, List<NavigationItem>>

@Serializable
class NavigationItem(
    val id: String,
    val label: String,
    val path: String,
    val target: String = "_self",
)