package three.two.bit.client.error

import three.two.bit.shared.model.ErrorResponse

class ValidationError(msg: String, val fields: List<ValidationField> ): Exception(msg) {
    data class ValidationField(val name: String, val message: String)
}

fun ErrorResponse.toValidationError() = ValidationError(
    msg = message,
    fields = fields.map { ValidationError.ValidationField(it.name, it.message) },
)