package three.two.bit.web.components.ui.css

import org.jetbrains.compose.web.css.*

// This adds extra MB to
object Styles : StyleSheet() {

    val fullHeight: String by style {
        height(100.percent)
        padding(0.px)
    }

    val centerCropped: String by style {
        backgroundSize("250%")
        backgroundPosition("center center")
        backgroundRepeat("no-repeat")
    }

    val divider: String by style {
        height(3.cssRem)
        borderWidth(1.px, 0.px)
        border {
            style = LineStyle.Solid
            color = Color("#35000000")
        }

        backgroundColor(Color("#25000000"))
        property("box-shadow", "inset 0 0.5em 1.5em rgb(0 0 0 / 10%), inset 0 0.125em 0.5em rgb(0 0 0 / 15%)")
    }

}
