package three.two.bit.web.components.ui.css

import org.jetbrains.compose.web.css.StyleSheet

object Bootstrap : StyleSheet() {

    const val containerFluid: String = "container-fluid"
    const val container: String = "container"
    const val row: String = "row"
    const val col: String = "col"
    const val rowCol1: String = "row-cols-1"
    const val rowColSm2: String = "row-cols-sm-2"
    const val rowColMd5: String = "row-cols-md-5"
    const val w100: String = "w-100"
    const val py5: String = "py-5"
    const val p0: String = "p-0"
    const val mb2: String = "mb-2"
    const val me2: String = "me-2"
    const val mb3: String = "mb-3"
    const val my5: String = "my-5"
    const val col5: String = "col-5"
    const val colLg: String = "col-lg"
    const val borderTop: String = "border-top"
    const val textMuted: String = "text-muted"
    const val navLink: String = "nav-link"
    const val navItem: String = "nav-item"
    const val nav: String = "nav"
    const val bi: String = "bi"
    const val flexColumn: String = "flex-column"
    const val alignItemsCenter: String = "align-items-center"

}