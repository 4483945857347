package three.two.bit.client.component

import com.arkivanov.decompose.ComponentContext
import com.arkivanov.decompose.value.MutableValue
import com.arkivanov.decompose.value.Value
import org.kodein.di.DI
import org.kodein.di.DIAware
import three.two.bit.client.component.IUserScreen.Model

class UserScreen(
    override val di: DI,
    componentContext: ComponentContext,
) : DIAware, IUserScreen, ComponentContext by componentContext {

    override val models: MutableValue<Model> = MutableValue(Model())

}

interface IUserScreen: DIAware {

    val models: Value<Model>

    data class Model(
        val email: String = "",
        val password: String = "",
        val requestID: Int = 0,
    )
}
