package three.two.bit.client.component

import com.arkivanov.decompose.ComponentContext
import com.arkivanov.decompose.value.MutableValue
import com.arkivanov.decompose.value.Value
import org.kodein.di.DI
import org.kodein.di.DIAware
import three.two.bit.client.component.ILandingScreen.Model

class LandingScreen(
    override val di: DI,
    componentContext: ComponentContext,
) : DIAware, ILandingScreen, ComponentContext by componentContext {

    override val models: MutableValue<Model> = MutableValue(Model())
}

interface ILandingScreen : DIAware {

    val models: Value<Model>

    data class Model(
        val email: String = "",
    )

}
