package three.two.bit.client.ui.forms



class FormState {
    var fields: List<Field> = listOf()
    var wasValidating = false
    var requestID = -1

    // called, when request returns error
    fun validateRequest(requestID: Int) {
        if (this.requestID != requestID) {
            validate()
            this.requestID = requestID
        }
    }

    fun validate(): Boolean {
        var valid = true
        wasValidating = true
        for (field in fields) if (!field.validate()) {
            valid = false
            break
        }
        return valid
    }

    fun getData(): Map<String, String> = fields.associate {
        it.name to it.fieldText
    }
}