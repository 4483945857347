package three.two.bit.web.components.views

import androidx.compose.runtime.Composable
import androidx.compose.runtime.getValue
import org.jetbrains.compose.web.dom.A
import org.jetbrains.compose.web.dom.AttrBuilderContext
import org.jetbrains.compose.web.dom.Li
import org.jetbrains.compose.web.dom.Text
import org.jetbrains.compose.web.dom.Ul
import org.w3c.dom.HTMLUListElement
import three.two.bit.client.annotation.KotlinDsl
import three.two.bit.client.component.IMenu
import three.two.bit.client.component.IMenu.Link.HttpLink
import three.two.bit.web.components.utils.subscribeAsState


@KotlinDsl
@Composable
fun MenuUI(
    menu: IMenu,
    attrs: AttrBuilderContext<HTMLUListElement>? = null,
    liClasses: Array<String> = arrayOf("nav-item"),
    aClasses: Array<String> = arrayOf("nav-link", "active"),
) {
    l.d { "MenuUI()" }
    val models by menu.models.subscribeAsState()

    Ul(attrs = attrs) {
        models.navItems.forEach { navItem ->
            Li(attrs = { classes(*liClasses) }) {
                val (link, target) = if (navItem is HttpLink) navItem.path to navItem.target else "#" to null
                A(
                    href = link,
                    attrs = {
                        target?.run { attr("target", target) }
                        classes(*aClasses)
                        if (link.length == 1) {
                            onClick {
                                it.preventDefault()
                                menu.navigate(navItem)
                            }
                        }
                    }
                ) {
                    Text(navItem.label)
                }
            }
        }
    }
}
