package three.two.bit.shared.model

import kotlinx.serialization.Serializable

@Serializable
data class ErrorResponse(
    val message: String,
    val fields: List<Field> = emptyList(),
) {
    @Serializable
    data class Field(val name: String, val message: String)
}