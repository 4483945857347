package three.two.bit.web.components.views

import androidx.compose.runtime.Composable
import androidx.compose.runtime.getValue
import three.two.bit.client.annotation.KotlinDsl
import three.two.bit.client.component.ILandingScreen
import three.two.bit.web.components.utils.subscribeAsState

@KotlinDsl
@Composable
fun LandingScreenUI(landingScreen: ILandingScreen) {
    val models by landingScreen.models.subscribeAsState()

}