package three.two.bit.shared.model

import io.konform.validation.Validation
import io.konform.validation.jsonschema.email
import io.konform.validation.jsonschema.maxLength
import io.konform.validation.jsonschema.minLength
import kotlinx.serialization.Serializable

@Serializable
data class User(
    val firstName: String = "",
    val lastName: String = "",
    val email: String = "@",
) {

    companion object {

        val validate = Validation<User> {
            User::firstName {
                minLength(2)
                maxLength(100)
            }

            User::lastName {
                minLength(2)
                maxLength(100)
            }

            User::email {
                minLength(2)
//                email()
            }
        }
    }
}