package three.two.bit.client.ui.forms


import androidx.compose.runtime.getValue
import androidx.compose.runtime.mutableStateOf
import androidx.compose.runtime.setValue
import three.two.bit.client.Global
import kotlin.random.Random

@Suppress("MemberVisibilityCanBePrivate")
class Field(
    val name: String,
    val label: String = "",
    val value: String = "",
    val inputType: InputType = InputType.Text,
    val validators: List<Validator> = emptyList(),
) {
    var fieldText: String by mutableStateOf(if (Global.debug && value.isBlank()) inputType.generateValue() else value)
    var fieldLabel: String by mutableStateOf(label)
    var fieldError: String? by mutableStateOf(null)
    var hasError: Boolean by mutableStateOf(false)

    @Suppress("unused")
    fun reset() {
        fieldText = value
    }

    fun showError(error: String) {
        hasError = true
        fieldError = error
    }

    fun hideError() {
        fieldLabel = label
        fieldError = null
        hasError = false
        validate()
    }

    fun validate(): Boolean {
        return validators.map {
            when (it) {
                is Email -> {
//                    TODO temporary disabled
//                    if (!it.regex.toRegex().containsMatchIn(fieldText)){
//                        showError(it.message)
//                        return@map false
//                    }
                    true
                }
                is Required -> {

                    if (fieldText.isEmpty()) {
                        showError(it.message)
                        return@map false
                    }
                    true
                }
                is Regex -> {
                    if (!it.regex.toRegex().containsMatchIn(fieldText)) {
                        showError(it.message)
                        return@map false
                    }
                    true
                }
                is EmailAlreadyExist -> {
                    println("EmailAlreadyExist $name $fieldText ")
                    if (it.email != null && it.email == fieldText) {
                        println("EmailAlreadyExist email is still same")
                        showError(it.message)
                        return@map false
                    }
                    true
                }
                is FieldServerError -> {
                    println("FieldServerError $name $fieldText ")
                    if (it.value != null && it.value == fieldText) {
                        println("FieldServerError value is still same as server validation")
                        showError(it.message)
                        return@map false
                    }
                    true
                }
            }
        }.all { it }
    }

    @Suppress("unused")
    enum class InputType {
        Text,
        Tel,
        Search,
        Color,
        Month,
        Week,
        Number,
        Time,
        Url,
        Date,
        DateTimeLocal {
            override fun type(): String = "datetime-local"
        },
        File,
        Name,
        Surname,
        Password {
            override fun generateValue(): String = "p@SSw0rd"
        },
        Street,
        City {
            override fun generateValue(): String = "Bratislava"
        },
        Country {
            override fun generateValue(): String = "Slovakia"
        },
        Region,
        Zip {
            override fun type(): String = "number"
            override fun generateValue(): String = generateNumbers(5)
        },
        Email {
            override fun generateValue(): String =
                "${generateString(4)}+32bit.reality@gmail.com"
        };

        companion object {
            val alphabet = "abcdefghijklmnopqrstuvxyz".toCharArray()

            fun generateString(size: Int): String {
                val random = Random.Default
                var i = size
                var res = ""
                while (i-- > 0) {
                    val index = random.nextInt(0, alphabet.lastIndex)
                    res += alphabet[index]
                }
                return res
            }

            fun generateNumbers(size: Int): String {
                val random = Random.Default
                var i = size
                var res = ""
                while (i-- > 0) {
                    res += random.nextInt(0, 9)
                }
                return res
            }
        }

        open fun generateValue() = generateString(8)
        open fun type() = name.lowercase()
    }
}
