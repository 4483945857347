package three.two.bit.web.components

import androidx.compose.runtime.Composable
import com.arkivanov.decompose.DefaultComponentContext
import com.arkivanov.decompose.router.stack.webhistory.DefaultWebHistoryController
import com.arkivanov.decompose.router.stack.webhistory.WebHistoryController
import com.arkivanov.essenty.lifecycle.LifecycleRegistry
import js.SiteConfig
import kotlinx.serialization.decodeFromString
import kotlinx.serialization.json.Json
import org.jetbrains.compose.web.css.Style
import org.kodein.di.DI
import org.kodein.di.bindSingleton
import org.kodein.di.instance
import three.two.bit.client.annotation.KotlinDsl
import three.two.bit.client.component.DeepLink
import three.two.bit.client.component.Root
import three.two.bit.client.di.ClientMainModule
import three.two.bit.shared.model.NavigationConfigList
import three.two.bit.web.components.ui.css.Styles
import three.two.bit.web.components.views.RootUI
import three.two.bit.web.di.localDI
import three.two.bit.web.di.withDI
import three.two.bit.web.history.createHistory

private val di by lazy {
    DI {
        val useHash = false
        val (_, path) = createHistory(useHash)
        val lifecycle = LifecycleRegistry()

        val navigation = Json.decodeFromString<NavigationConfigList>(SiteConfig.navigation)

        SiteConfig.user?.let { bindSingleton { it } }

        bindSingleton { navigation }
        bindSingleton<DeepLink> { DeepLink.Web(path = path) }
        bindSingleton { lifecycle }
        bindSingleton { DefaultComponentContext(lifecycle = lifecycle) }
        bindSingleton<WebHistoryController> { DefaultWebHistoryController() }
        import(ClientMainModule.api)
    }
}

@KotlinDsl
@Composable
fun Body() = withDI(di) {
    Style(Styles)
    val context by localDI().instance<DefaultComponentContext>()
    val root = Root(
        localDI(),
        context,
    )

    RootUI(root)
}
