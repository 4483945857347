package three.two.bit.shared.di

import kotlinx.coroutines.Dispatchers
import org.kodein.di.DI
import org.kodein.di.bind
import org.kodein.di.singleton

actual object SharedModule {
    actual val shared = DI.Module(name = "SHARED") {
        bind { singleton { Dispatchers.Default } }
    }
}
