package three.two.bit.client.router

import com.arkivanov.decompose.ComponentContext
import com.arkivanov.decompose.router.stack.ChildStack
import com.arkivanov.decompose.router.stack.StackNavigation
import com.arkivanov.decompose.router.stack.childStack
import com.arkivanov.decompose.router.stack.webhistory.WebHistoryController
import com.arkivanov.decompose.value.Value
import l
import org.kodein.di.DI
import org.kodein.di.DIAware
import org.kodein.di.bind
import org.kodein.di.instanceOrNull
import org.kodein.di.singleton
import three.two.bit.client.component.Child
import three.two.bit.client.component.DeepLink
import three.two.bit.client.component.LandingScreen
import three.two.bit.client.component.LoginForm
import three.two.bit.client.component.Page
import three.two.bit.client.component.RegistrationForm
import three.two.bit.client.component.Unknown
import three.two.bit.client.component.UserScreen

class RootRouter(
    override val di: DI,
    componentContext: ComponentContext,
) : DIAware, ComponentContext by componentContext, IRootRouter {

    private val deepLink by instanceOrNull<DeepLink>()
    private val navigation = StackNavigation<Page>()
    private val webHistoryController by instanceOrNull<WebHistoryController>()

    override val stack: Value<ChildStack<Page, Child>> = childStack(
        source = navigation,
        initialStack = { getInitialStack(deepLink) },
        handleBackButton = true, // Pop the back stack on back button press
        childFactory = ::createChild,
    )

    private fun childDi() = DI {
        extend(di)
        bind { singleton { navigation } }
    }

    init {
        webHistoryController?.attach(
            navigator = navigation,
            stack = stack,
            getPath = { it.path },
            getConfiguration = { it.extractPageFromPath() }
        )
    }

    override fun navigateToPage(page: Page) {
        l.d { "navigateToPage ${page.path}" }
        val c = stack.value.items.joinToString { it.configuration.path }
        l.d { "stack $c" }

        navigation.navigate(
            transformer = { stack ->
                when (page) {
                    Page.Landing -> listOf(page)
                    Page.Login -> listOf(Page.Landing, page)
                    Page.Register, Page.Login -> listOf(Page.Landing, Page.Login, page)
                    else -> stack.filterNot { it::class == page::class } + page
                }
            },
            onComplete = { newStack, oldStack ->
                val n = newStack.joinToString { it.path }
                val o = oldStack.joinToString { it.path }
                l.d { "page loaded ${page.path}: newStack = { $n } oldStack = { $o }" }
            }
        )
    }

    private fun createChild(page: Page, componentContext: ComponentContext): Child {
        return Child(
            page = page,
            component = when (page) {
                Page.Login -> LoginForm(di = childDi(), componentContext = componentContext)
                Page.Register -> RegistrationForm(
                    di = childDi(),
                    componentContext = componentContext
                )
                Page.Landing -> LandingScreen(di = childDi(), componentContext = componentContext)
                Page.User -> UserScreen(di = childDi(), componentContext = componentContext)
                Page.Unknown -> Unknown(di = childDi(), componentContext = componentContext)
            },
            isBackEnabled = { stack.value.backStack.isNotEmpty() }
        )
    }

    private fun getInitialStack(deepLink: DeepLink?): List<Page> =
        setOfNotNull(Page.Landing, deepLink?.extractPageFromPath()).toList()


    private fun DeepLink.extractPageFromPath(): Page {
        l.d { toString() }
        return when (this) {
            is DeepLink.PageDeeplink -> page
            is DeepLink.Web -> path.trimStart('#').extractPageFromPath()
        }
    }

    private fun String.extractPageFromPath(): Page = when {
        Page.Landing.path == this -> Page.Landing
        startsWith(Page.User.path) -> Page.User
        startsWith(Page.Login.path) -> Page.Login
        startsWith(Page.Register.path) -> Page.Register
        else -> Page.Unknown
    }

}

interface IRootRouter {
    val stack: Value<ChildStack<Page, Child>>
    fun navigateToPage(page: Page)
}
