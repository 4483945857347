package three.two.bit.client.di

import io.ktor.client.engine.js.JsClient
import org.kodein.di.DI
import org.kodein.di.bind
import org.kodein.di.singleton

actual object ClientMppModule {

    actual val api = DI.Module(name = "MPP") {
        bind { singleton { JsClient() } }
    }

}