package three.two.bit.client.component

import com.arkivanov.decompose.ComponentContext
import com.arkivanov.decompose.childContext
import com.arkivanov.decompose.router.stack.ChildStack
import com.arkivanov.decompose.value.Value
import com.arkivanov.essenty.parcelable.Parcelable
import com.arkivanov.essenty.parcelable.Parcelize
import org.kodein.di.DI
import org.kodein.di.DIAware
import org.kodein.di.bindSingleton
import org.kodein.di.instance
import three.two.bit.client.router.IRootRouter
import three.two.bit.client.router.RootRouter


class Root(
    override val di: DI,
    componentContext: ComponentContext,
) : DIAware, IRoot, ComponentContext by componentContext {

    private val childDi = DI {
        extend(di)
        bindSingleton<IRootRouter> { RootRouter(di, componentContext) }
    }

    private val router by childDi.instance<IRootRouter>()

    override val header: IHeader = Header(childDi, childContext(key = "header"))
    override val footer: IFooter = Footer(childDi, childContext(key = "footer"))
    override val childStack: Value<ChildStack<Page, Child>> get() = router.stack
}

sealed class Page(
    val label: String,
    val path: String,
    val accessLevel: AccessLevel = AccessLevel.Default
) : Parcelable {

    companion object {
        val pages = setOf(
            Landing, Login, Register, User, Unknown,
        )
    }

    @Parcelize
    object Landing : Page(
        label = "Home",
        path = "/",
    )

    @Parcelize
    object Login : Page(
        label = "Login",
        path = "/login",
        accessLevel = AccessLevel.NotLoggedIn,
    )

    @Parcelize
    object Register : Page(
        label = "Register",
        path = "/register",
        accessLevel = AccessLevel.NotLoggedIn,
    )

    @Parcelize
    object User : Page(
        label = "Home",
        path = "/user",
        accessLevel = AccessLevel.User,
    )

    @Parcelize
    object Unknown : Page(
        label = "Unknown",
        path = "/404",
    )
}

enum class AccessLevel {
    Default,
    NotLoggedIn,
    User,
}

sealed interface DeepLink {
    data class PageDeeplink(val page: Page) : DeepLink
    data class Web(val path: String) : DeepLink
}

@Suppress("unused")
class Child(
    val page: Page,
    val component: Any,
    val isBackEnabled: () -> Boolean
)

interface IRoot {
    val childStack: Value<ChildStack<*, Child>>
    val header: IHeader
    val footer: IFooter
}
