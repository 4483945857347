package three.two.bit.web.components.views

import androidx.compose.runtime.Composable
import androidx.compose.runtime.getValue
import dev.petuska.kmdc.core.classes
import org.jetbrains.compose.web.ExperimentalComposeWebSvgApi
import org.jetbrains.compose.web.dom.A
import org.jetbrains.compose.web.dom.Div
import org.jetbrains.compose.web.dom.Footer
import org.jetbrains.compose.web.dom.H5
import org.jetbrains.compose.web.dom.P
import org.jetbrains.compose.web.dom.Text
import org.jetbrains.compose.web.svg.Svg
import org.jetbrains.compose.web.svg.Use
import three.two.bit.client.annotation.KotlinDsl
import three.two.bit.client.component.IFooter
import three.two.bit.web.components.ui.css.Bootstrap
import three.two.bit.web.components.ui.css.Bootstrap.bi
import three.two.bit.web.components.ui.css.Bootstrap.borderTop
import three.two.bit.web.components.ui.css.Bootstrap.col
import three.two.bit.web.components.ui.css.Bootstrap.flexColumn
import three.two.bit.web.components.ui.css.Bootstrap.mb2
import three.two.bit.web.components.ui.css.Bootstrap.mb3
import three.two.bit.web.components.ui.css.Bootstrap.me2
import three.two.bit.web.components.ui.css.Bootstrap.my5
import three.two.bit.web.components.ui.css.Bootstrap.nav
import three.two.bit.web.components.ui.css.Bootstrap.navItem
import three.two.bit.web.components.ui.css.Bootstrap.navLink
import three.two.bit.web.components.ui.css.Bootstrap.p0
import three.two.bit.web.components.ui.css.Bootstrap.py5
import three.two.bit.web.components.ui.css.Bootstrap.row
import three.two.bit.web.components.ui.css.Bootstrap.rowCol1
import three.two.bit.web.components.ui.css.Bootstrap.rowColMd5
import three.two.bit.web.components.ui.css.Bootstrap.rowColSm2
import three.two.bit.web.components.ui.css.Bootstrap.textMuted
import three.two.bit.web.components.ui.css.Styles
import three.two.bit.web.components.utils.subscribeAsState

@OptIn(ExperimentalComposeWebSvgApi::class)
@KotlinDsl
@Composable
fun FooterUI(footer: IFooter) {
    l.d { "HeaderUI()" }
    val models by footer.models.subscribeAsState()

    Div(attrs = { classes(Styles.divider) }) {}

    Div(attrs = { classes(Bootstrap.container) }) {
        Footer(attrs = {
            classes(row, rowCol1, rowColSm2, rowColMd5, py5, my5, borderTop)
        }) {
            Div(attrs = { classes(col, mb3) }) {
                A {
                    Svg(attrs = {
                        classes(bi, me2)
                        this.attr("width", "40")
                        this.attr("height", "32")
                    }) {
                        Use(href = "#bootstrap") {}
                    }
                }

                P(attrs = { classes(textMuted) }) {
                    Text("© 2022 ")
                    A(href = "http://32bit.sk", attrs = { classes("textMuted") }) { Text("32bit s.r.o.") }
                }
            }

            Div(attrs = { classes(col, mb3) }) {

            }

            Div(attrs = { classes(col, mb3) }) {
                H5 { Text("Section") }

                MenuUI(
                    footer.menu,
                    attrs = { classes(nav, flexColumn) },
                    liClasses = arrayOf(navItem, mb2),
                    aClasses = arrayOf(navLink, p0, textMuted),
                )
            }

            Div(attrs = { classes(col, mb3) }) {
                H5 { Text("Section") }

                MenuUI(
                    footer.menu,
                    attrs = { classes(nav, flexColumn) },
                    liClasses = arrayOf(navItem, mb2),
                    aClasses = arrayOf(navLink, p0, textMuted),
                )
            }

            Div(attrs = { classes(col, mb3) }) {
                H5 { Text("Section") }

                MenuUI(
                    footer.menu,
                    attrs = { classes(nav, flexColumn) },
                    liClasses = arrayOf(navItem, mb2),
                    aClasses = arrayOf(navLink, p0, textMuted),
                )
            }
        }
    }
}
