package three.two.bit.web.components.views

import androidx.compose.runtime.Composable
import androidx.compose.runtime.getValue
import three.two.bit.client.annotation.KotlinDsl
import three.two.bit.client.component.ILandingScreen
import three.two.bit.client.component.IUserScreen
import three.two.bit.client.component.UserScreen
import three.two.bit.web.components.utils.subscribeAsState

@KotlinDsl
@Composable
fun UserScreenUI(userScreen: IUserScreen) {
    val models by userScreen.models.subscribeAsState()

}