package three.two.bit.shared.model

import io.konform.validation.Validation
import io.konform.validation.jsonschema.minLength
import kotlinx.serialization.Serializable

@Serializable
data class Address(
    val street: String = "",
    val region: String = "",
    val city: String = "",
    val country: String = "",
    val zip: String = "",
) {
    companion object {

        val validate = Validation<Address> {
            Address::street {
                minLength(2)
            }

            Address::city {
                minLength(2)
            }

            Address::country {
                minLength(2)
            }
        }
    }
}