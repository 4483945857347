package three.two.bit.web.components.ui

import androidx.compose.runtime.Composable
import org.jetbrains.compose.web.css.Color
import org.jetbrains.compose.web.css.backgroundColor
import org.jetbrains.compose.web.css.backgroundImage
import org.jetbrains.compose.web.css.margin
import org.jetbrains.compose.web.css.px
import org.jetbrains.compose.web.dom.ContentBuilder
import org.jetbrains.compose.web.dom.Div
import org.w3c.dom.HTMLDivElement
import three.two.bit.client.annotation.KotlinDsl
import three.two.bit.web.components.ui.css.Bootstrap.alignItemsCenter
import three.two.bit.web.components.ui.css.Bootstrap.col5
import three.two.bit.web.components.ui.css.Bootstrap.colLg
import three.two.bit.web.components.ui.css.Bootstrap.containerFluid
import three.two.bit.web.components.ui.css.Bootstrap.row
import three.two.bit.web.components.ui.css.Bootstrap.w100
import three.two.bit.web.components.ui.css.Styles.centerCropped
import three.two.bit.web.components.ui.css.Styles.fullHeight

@KotlinDsl
@Composable
fun DivideLayout(
    url: String,
    content: ContentBuilder<HTMLDivElement>? = null
) {
    Div(attrs = {
        classes(
            containerFluid,
            fullHeight
        ); style { backgroundColor(Color("#0390fc")) }
    }) {
        Div(attrs = { classes(row, fullHeight) }) {
            Div(attrs = { classes(col5, row, alignItemsCenter) }) content@{
                content?.invoke(this@content)
            }
            Div(attrs = { classes(colLg, fullHeight) }) {
                Div(attrs = {
                    classes(centerCropped, w100, fullHeight)
                    style {
                        margin(0.px)
                        backgroundImage("url($url)")
                    }
                })
            }
        }
    }
}
