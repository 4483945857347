package three.two.bit.client.ui.forms

private const val EMAIL_MESSAGE = "Invalid email address"
private const val REQUIRED_MESSAGE = "This field is required"
private const val REGEX_MESSAGE = "Value does not match the regex"
private const val EMAIL_EXISTS_MESSAGE = "User with this email already exists"
private const val SERVER_ERROR_MESSAGE = "User with this email already exists"

sealed interface Validator
open class Email(var message: String = EMAIL_MESSAGE): Validator
open class Required(var message: String = REQUIRED_MESSAGE): Validator
open class Regex(var message: String, var regex: String = REGEX_MESSAGE): Validator
open class EmailAlreadyExist(val email: String?, var message: String = EMAIL_EXISTS_MESSAGE): Validator
open class FieldServerError(val value: String?, var message: String = SERVER_ERROR_MESSAGE): Validator