package three.two.bit.client.component

import com.arkivanov.decompose.ComponentContext
import com.arkivanov.decompose.childContext
import com.arkivanov.decompose.value.MutableValue
import com.arkivanov.decompose.value.Value
import org.kodein.di.DI
import org.kodein.di.DIAware
import org.kodein.di.instance
import three.two.bit.client.component.IFooter.Model
import three.two.bit.client.manager.UserManager
import three.two.bit.client.router.IRootRouter
import three.two.bit.shared.model.User

class Footer(
    override val di: DI,
    componentContext: ComponentContext,
) : DIAware, IFooter, ComponentContext by componentContext {

    private val rootRooter by instance<IRootRouter>()
    private val userManager by instance<UserManager>()

    override val menu: IMenu = Menu(di, childContext(key = "footerMenu"), "footer")

    override val models: MutableValue<Model> = MutableValue(Model(userManager.user))

}

interface IFooter : DIAware {

    val models: Value<Model>
    val menu: IMenu

    data class Model(
        val loggedUser: User? = null,
    )


}
