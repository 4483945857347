package three.two.bit.client.repository

import io.ktor.client.HttpClient
import io.ktor.client.call.body
import io.ktor.client.request.get
import io.ktor.client.request.patch
import io.ktor.client.request.setBody
import io.ktor.client.statement.HttpResponse
import io.ktor.http.ContentType
import io.ktor.http.HttpStatusCode
import io.ktor.http.contentType
import kotlinx.coroutines.CoroutineDispatcher
import kotlinx.coroutines.withContext
import org.kodein.di.DI
import org.kodein.di.DIAware
import org.kodein.di.instance
import three.two.bit.client.error.toValidationError
import three.two.bit.client.repository.UserRemoteRepository.AuthNotSuccessful
import three.two.bit.client.repository.UserRemoteRepository.UnknownUserError
import three.two.bit.client.repository.UserRemoteRepository.UserAlreadyExist
import three.two.bit.shared.model.Address
import three.two.bit.shared.model.AuthResponse
import three.two.bit.shared.model.ErrorResponse
import three.two.bit.shared.model.LoginRequest
import three.two.bit.shared.model.RegisterRequest
import three.two.bit.shared.model.User

class UserRemoteRepositoryImpl(override val di: DI) : DIAware, UserRemoteRepository {

    private val client by instance<HttpClient>()
    private val ioDispatcher by instance<CoroutineDispatcher>()

    override suspend fun checkIdentity(): User = withContext(ioDispatcher) {
        val response = client.get("/api/user/identity") {
            l.d { "checkIdentity" }
        }

        processLoginResponse(response)
    }

    override suspend fun logout() {
        val response = client.get("/api/user/logout") {
            l.d { "logout" }
        }
    }

    override suspend fun loginUser(email: String, password: String): User =
        withContext(ioDispatcher) {
            val response = client.patch("/api/user/login") {
                contentType(ContentType.Application.Json)
                setBody(LoginRequest(email, password))
                l.d { "loginUser" }
            }

            processLoginResponse(response)
        }

    override suspend fun registerUser(user: User, address: Address, password: String): User =
        withContext(ioDispatcher) {
            val response = client.patch("/api/user/register") {
                contentType(ContentType.Application.Json)
                setBody(RegisterRequest(user, address, password))
                l.d { "registerUser" }
            }

            when (response.status) {
                HttpStatusCode.Created -> {
                    val body = response.body<AuthResponse>()

                    body.user ?: throw UnknownUserError(msg = body.message)
                }
                HttpStatusCode.BadRequest -> {
                    val body = response.body<ErrorResponse>()
                    throw body.toValidationError()
                }
                HttpStatusCode.Conflict -> {
                    val body = response.body<ErrorResponse>()
                    throw UserAlreadyExist(msg = body.message)
                }
                else -> {
                    val body = response.body<ErrorResponse>()
                    throw UnknownUserError(msg = body.message)
                }
            }
        }

    private suspend fun processLoginResponse(response: HttpResponse): User {
        return when (response.status) {
            HttpStatusCode.OK -> {
                val body = response.body<AuthResponse>()

                body.user ?: throw UnknownUserError(msg = body.message)
            }
            HttpStatusCode.Forbidden -> {
                val body = response.body<ErrorResponse>()
                throw AuthNotSuccessful(msg = body.message)
            }
            else -> {
                val body = response.body<ErrorResponse>()
                throw UnknownUserError(msg = body.message)
            }
        }
    }
}
