package three.two.bit.client.component

import com.arkivanov.decompose.ComponentContext
import com.arkivanov.decompose.childContext
import com.arkivanov.decompose.value.MutableValue
import com.arkivanov.decompose.value.Value
import com.arkivanov.decompose.value.reduce
import com.arkivanov.essenty.lifecycle.Lifecycle
import com.arkivanov.essenty.lifecycle.LifecycleOwner
import com.arkivanov.essenty.lifecycle.doOnDestroy
import kotlinx.coroutines.CoroutineScope
import kotlinx.coroutines.cancel
import kotlinx.coroutines.launch
import org.kodein.di.DI
import org.kodein.di.DIAware
import org.kodein.di.instance
import three.two.bit.client.component.IHeader.Model
import three.two.bit.client.manager.UserManager
import three.two.bit.client.router.IRootRouter
import three.two.bit.shared.model.User
import kotlin.coroutines.CoroutineContext
import kotlinx.coroutines.Dispatchers
import kotlinx.coroutines.SupervisorJob

class Header(
    override val di: DI,
    componentContext: ComponentContext,
) : DIAware, IHeader, ComponentContext by componentContext {

    private val rootRooter by instance<IRootRouter>()
    private val userManager by instance<UserManager>()

    // The scope is automatically cancelled when the component is destroyed
    private val scope = coroutineScope(Dispatchers.Main + SupervisorJob())
    private val scope2 by instance<CoroutineScope>()

    override val menu: IMenu = Menu(di, childContext(key = "headerMenu"), "header")

    // TODO subscribe for events
    override val models: MutableValue<Model> = MutableValue(Model(userManager.user))

    init {
        scope.launch {
            while (true) {
                val user = userManager.userChannel.receive()
                l.d { "Header user = $user" }
                models.reduce { it.copy(loggedUser = user) }
            }
        }
    }

    override fun navigateToLogo() {
        rootRooter.navigateToPage(Page.Landing)
    }

    override fun navigateToLogin() {
        rootRooter.navigateToPage(Page.Login)
    }

    override fun navigateToRegister() {
        rootRooter.navigateToPage(Page.Register)
    }

    override fun navigateToLogout() {
        userManager.logoutUser()
        rootRooter.navigateToPage(Page.Landing)
    }
}

interface IHeader : DIAware {

    val models: Value<Model>
    val menu: IMenu

    data class Model(
        val loggedUser: User? = null,
    )

    fun navigateToLogo()
    fun navigateToLogin()
    fun navigateToRegister()
    fun navigateToLogout()

}

fun CoroutineScope(context: CoroutineContext, lifecycle: Lifecycle): CoroutineScope {
    val scope = CoroutineScope(context)
    lifecycle.doOnDestroy(scope::cancel)
    return scope
}

fun LifecycleOwner.coroutineScope(context: CoroutineContext): CoroutineScope =
    CoroutineScope(context, lifecycle)