package three.two.bit.web.di

import androidx.compose.runtime.Composable
import androidx.compose.runtime.CompositionLocalProvider
import androidx.compose.runtime.ProvidableCompositionLocal
import androidx.compose.runtime.compositionLocalOf
import org.kodein.di.DI

val LocalDI: ProvidableCompositionLocal<DI?> = compositionLocalOf { null }

@Composable
fun withDI(builder: DI.MainBuilder.() -> Unit, content: @Composable () -> Unit): Unit =
    CompositionLocalProvider(LocalDI provides DI { builder() }) { content() }

@Composable
fun withDI(di: DI, content: @Composable () -> Unit): Unit =
    CompositionLocalProvider(LocalDI provides di) { content() }

@Composable
fun diFromAppContext(): DI = error("Missing DI container!")

@Composable
fun localDI(): DI = LocalDI.current ?: diFromAppContext()