package three.two.bit.client.component

import com.arkivanov.decompose.ComponentContext
import org.kodein.di.DI
import org.kodein.di.DIAware

class Unknown(
    override val di: DI,
    componentContext: ComponentContext,
) : DIAware, IUnknown, ComponentContext by componentContext {

}

interface IUnknown {

}