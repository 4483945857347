package three.two.bit.client.component

import com.arkivanov.decompose.ComponentContext
import com.arkivanov.decompose.value.MutableValue
import com.arkivanov.decompose.value.Value
import com.arkivanov.decompose.value.reduce
import kotlinx.coroutines.CoroutineExceptionHandler
import kotlinx.coroutines.CoroutineScope
import kotlinx.coroutines.launch
import org.kodein.di.DI
import org.kodein.di.DIAware
import org.kodein.di.instance
import three.two.bit.client.component.ILoginForm.Model
import three.two.bit.client.di.ClientMainModule
import three.two.bit.client.manager.UserManager
import three.two.bit.client.repository.UserRemoteRepository
import three.two.bit.client.router.IRootRouter

class LoginForm(
    override val di: DI,
    componentContext: ComponentContext,
) : DIAware, ILoginForm, ComponentContext by componentContext {

    private val rootRooter by instance<IRootRouter>()
    private val userManager by instance<UserManager>()
    private val scope by instance<CoroutineScope>()
    private val serverUrl by instance<String>(ClientMainModule.SERVER_URL)

    override val url: String get() = "$serverUrl/auth/login"
    override val models: MutableValue<Model> = MutableValue(Model())

    override fun onLoginClicked(data: Map<String, String>) {
        val e = CoroutineExceptionHandler { _, throwable ->
            when (throwable) {
                is UserRemoteRepository.AuthNotSuccessful -> {
                    models.reduce {
                        it.copy(invalidEmail = it.email, invalidPassword = it.password)
                    }
                }
            }
            l.e(throwable) { throwable.message.toString() }
        }

        scope.launch(e) {
            models.reduce { it.dataToModel(data) }
            val user = models.value.run {
                userManager.loginUser(email, password)
            }
            l.d { user.toString() }
            rootRooter.navigateToPage(Page.User)
        }
    }

    override fun onRegisterClicked() {
        rootRooter.navigateToPage(Page.Register)
    }

    private fun Model.dataToModel(data: Map<String, String>) = copy(
        email = data[Model::email.name] ?: "",
        password = data[Model::password.name] ?: "",
        invalidEmail = null,
        invalidPassword = null,
        requestID = requestID + 1,
    )
}

interface ILoginForm : DIAware {

    val url: String

    val models: Value<Model>

    fun onLoginClicked(data: Map<String, String>)

    fun onRegisterClicked()

    data class Model(
        val email: String = "",
        val password: String = "",
        val invalidEmail: String? = null,
        val invalidPassword: String? = null,
        val requestID: Int = 0,
    )
}
