package three.two.bit.web.components.views

import androidx.compose.runtime.Composable
import androidx.compose.runtime.getValue
import androidx.compose.runtime.mutableStateOf
import androidx.compose.runtime.remember
import dev.petuska.kmdc.button.MDCButton
import dev.petuska.kmdc.button.MDCButtonType
import dev.petuska.kmdc.card.MDCCard
import dev.petuska.kmdc.layout.grid.MDCLayoutGrid
import kotlinx.browser.window
import org.jetbrains.compose.web.css.AlignContent
import org.jetbrains.compose.web.css.alignContent
import org.jetbrains.compose.web.css.margin
import org.jetbrains.compose.web.css.padding
import org.jetbrains.compose.web.css.px
import org.jetbrains.compose.web.css.textAlign
import org.jetbrains.compose.web.dom.Div
import org.jetbrains.compose.web.dom.Form
import three.two.bit.client.annotation.KotlinDsl
import three.two.bit.client.component.ILoginForm
import three.two.bit.client.component.IRegistrationForm.Model
import three.two.bit.client.ui.forms.Email
import three.two.bit.client.ui.forms.Field
import three.two.bit.client.ui.forms.FieldServerError
import three.two.bit.client.ui.forms.FormState
import three.two.bit.client.ui.forms.Required
import three.two.bit.web.components.forms.FormView
import three.two.bit.web.components.ui.DivideLayout
import three.two.bit.web.components.utils.subscribeAsState

@KotlinDsl
@Composable
fun LoginFormUI(loginForm: ILoginForm) {
    val models by loginForm.models.subscribeAsState()
    val state by remember { mutableStateOf(FormState()) }

    DivideLayout("images/login.jpg") {
        MDCLayoutGrid {
            Form(attrs = {
                this.addEventListener("submit") {
                    it.preventDefault()
                }
            }) {
                MDCCard(
                    attrs = {
                        style {
                            padding(20.px)
                            textAlign("center")
                            alignContent(AlignContent.Center)
                        }
                    }
                ) {
                    FormView(
                        state = state,
                        fields = listOf(
                            Field(
                                name = Model::email.name,
                                value = models.email,
                                label = "Email",
                                inputType = Field.InputType.Email,
                                validators = listOf(
                                    Required(),
                                    Email(),
                                    FieldServerError(
                                        models.invalidEmail,
                                        "Invalid email or password"
                                    )
                                )
                            ),
                            Field(
                                name = Model::password.name,
                                label = "Password",
                                inputType = Field.InputType.Password,
                                validators = listOf(
                                    Required(),
                                    FieldServerError(
                                        models.invalidEmail,
                                        "Invalid email or password",
                                    )
                                )
                            ),
                        )
                    )
                    Div {
                        MDCButton(
                            text = "Login!",
                            type = MDCButtonType.Outlined,
                            attrs = {
                                style {
                                    margin(3.px)
                                }
                                onClick {
                                    if (state.validate()) {
                                        loginForm.onLoginClicked(state.getData())
                                    }
                                }
                            }
                        )

                        MDCButton(
                            text = "Google!",
                            type = MDCButtonType.Outlined,
                            attrs = {
                                style {
                                    margin(3.px)
                                }
                                onClick {
                                    window.open(
                                        loginForm.url,
                                        "_blank",
                                        "location=yes,height=570,width=520,scrollbars=yes,status=yes"
                                    )
                                }
                            }
                        )

                        MDCButton(
                            text = "Register!",
                            type = MDCButtonType.Outlined,
                            attrs = {
                                style {
                                    margin(3.px)
                                }
                                onClick { loginForm.onRegisterClicked() }
                            }
                        )
                    }
                }
            }
        }
    }

    if (models.invalidEmail != null || models.invalidPassword != null) {
        state.validateRequest(models.requestID)
    }
}
