package three.two.bit.web.components.ui

import androidx.compose.runtime.Composable
import dev.petuska.kmdc.core.KMDCInternalAPI
import dev.petuska.kmdc.core.aria
import dev.petuska.kmdc.core.data
import org.jetbrains.compose.web.dom.A
import org.jetbrains.compose.web.dom.Button
import org.jetbrains.compose.web.dom.Div
import org.jetbrains.compose.web.dom.ElementScope
import org.jetbrains.compose.web.dom.Nav
import org.jetbrains.compose.web.dom.Span
import org.jetbrains.compose.web.dom.Text
import org.w3c.dom.HTMLElement
import three.two.bit.client.annotation.KotlinDsl


@OptIn(KMDCInternalAPI::class)
@KotlinDsl
@Composable
fun NavBar(content: (@Composable ElementScope<HTMLElement>.() -> Unit)?) {
    Nav(attrs = {
        classes(
            "navbar",
            "fixed-top",
            "navbar-expand-md",
            "navbar-light",
            "bg-light",
        )
    }) {
        Div(attrs = { classes("container-fluid") }) {
            A(href = "#", attrs = { classes("navbar-brand") }) {
                Text("Header")
            }
            Button(attrs = {
                classes("navbar-toggler", "collapsed")
                data("bs-toggle", "collapse")
                data("bs-target", "#navbarCollapse")
                aria("controls", "navbarCollapse")
                aria("expanded", "false")
                aria("label", "Toggle navigation")
            }

            ) { Span(attrs = { classes("navbar-toggler-icon") }) }

            Div(attrs = {
                id("navbarCollapse")
                classes("navbar-collapse", "collapse")
            }) {
                content?.invoke(this)
            }
        }
    }
}