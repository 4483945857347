package three.two.bit.web.components.views

import androidx.compose.runtime.Composable
import androidx.compose.runtime.getValue
import org.jetbrains.compose.web.dom.Button
import org.jetbrains.compose.web.dom.Div
import org.jetbrains.compose.web.dom.Text
import three.two.bit.client.annotation.KotlinDsl
import three.two.bit.client.component.IHeader
import three.two.bit.web.components.ui.NavBar
import three.two.bit.web.components.utils.subscribeAsState

@KotlinDsl
@Composable
fun HeaderUI(header: IHeader) {
    l.d { "HeaderUI()" }
    val models by header.models.subscribeAsState()

    NavBar {
        MenuUI(header.menu,
            attrs = { classes("navbar-nav", "me-auto", "mb-2", "mb-lg-0") }
        )

        Div(attrs = { classes("d-flex", "justify-content-end", "gap-2") }) {

            if (models.loggedUser == null) {
                Button(attrs = {
                    classes("btn", "btn-outline-primary", "btn-sm")
                    onClick { header.navigateToRegister() }
                }) {
                    Text("Register")
                }

                Button(attrs = {
                    classes("btn", "btn-primary", "btn-sm")
                    onClick { header.navigateToLogin() }
                }) {
                    Text("Login")
                }
            } else {
                Button(attrs = {
                    classes("btn", "btn-primary", "btn-sm")
                    onClick { header.navigateToLogout() }
                }) {
                    Text("Logout")
                }
            }
        }
    }
}
