package three.two.bit.client.component

import com.arkivanov.decompose.ComponentContext
import com.arkivanov.decompose.value.MutableValue
import com.arkivanov.decompose.value.Value
import com.arkivanov.decompose.value.reduce
import kotlinx.coroutines.CoroutineExceptionHandler
import kotlinx.coroutines.CoroutineScope
import kotlinx.coroutines.launch
import org.kodein.di.DI
import org.kodein.di.DIAware
import org.kodein.di.instance
import three.two.bit.client.component.IRegistrationForm.Model
import three.two.bit.client.manager.UserManager
import three.two.bit.client.repository.UserRemoteRepository
import three.two.bit.client.router.IRootRouter
import three.two.bit.shared.model.Address
import three.two.bit.shared.model.User

class RegistrationForm(
    override val di: DI,
    componentContext: ComponentContext,
) : DIAware, IRegistrationForm, ComponentContext by componentContext {

    private val rootRooter by instance<IRootRouter>()
    private val userManager by instance<UserManager>()
    private val scope by instance<CoroutineScope>()

    override val models: MutableValue<Model> = MutableValue(Model())

    override fun onSubmitClicked(data: Map<String, String>) {
        val e = CoroutineExceptionHandler { _, throwable ->
            when (throwable) {
                is UserRemoteRepository.UserAlreadyExist -> {
                    models.reduce { it.copy(emailAlreadyExist = it.email) }
                }
            }

            l.e(throwable) { throwable.message.toString() }
        }

        scope.launch(e) {
            models.reduce { it.dataToModel(data) }
            val user = models.value.run {
                userManager.registerUser(
                    User(
                        firstName = firstName,
                        lastName = lastName,
                        email = email,
                    ),
                    Address(
                        street = street,
                        region = region,
                        city = city,
                        country = country,
                        zip = zip,
                    ),
                    password = password
                )
            }
            l.d { user.toString() }
            rootRooter.navigateToPage(Page.User)
        }
    }

    private fun Model.dataToModel(data: Map<String, String>) = copy(
        firstName = data[Model::firstName.name] ?: "",
        lastName = data[Model::lastName.name] ?: "",
        email = data[Model::email.name] ?: "",
        street = data[Model::street.name] ?: "",
        region = data[Model::region.name] ?: "",
        city = data[Model::city.name] ?: "",
        country = data[Model::country.name] ?: "",
        zip = data[Model::zip.name] ?: "",
        password = data[Model::password.name] ?: "",
        emailAlreadyExist = null,
        requestID = requestID + 1,
    )
}

interface IRegistrationForm {

    val models: Value<Model>

    fun onSubmitClicked(data: Map<String, String>)

    data class Model(
        val firstName: String = "",
        val lastName: String = "",
        val email: String = "",
        val street: String = "",
        val region: String = "",
        val city: String = "",
        val zip: String = "",
        val country: String = "",
        val password: String = "",
        val confirmPassword: String = "",
        val requestID: Int = 0,
        val emailAlreadyExist: String? = null,
    )

}
