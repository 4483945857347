package three.two.bit.client.repository

import three.two.bit.shared.model.Address
import three.two.bit.shared.model.User

interface UserRemoteRepository {

    suspend fun checkIdentity(): User

    suspend fun logout()

    suspend fun loginUser(email: String, password: String): User

    suspend fun registerUser(user: User, address: Address, password: String): User

    class UserAlreadyExist(msg: String): Exception(msg)

    class AuthNotSuccessful(msg: String): Exception(msg)

    class UnknownUserError(msg: String): Exception(msg)


}