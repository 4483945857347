package three.two.bit.shared.model

import kotlinx.serialization.Serializable

@Serializable
data class AuthResponse(
    val message: String,
    val status: Status,
    val user: User? = null,
    val token: String? = null,
) {

    @Serializable
    enum class Status {
        OK,
    }
}