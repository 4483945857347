package three.two.bit.client.di

import io.ktor.client.HttpClient
import io.ktor.client.engine.HttpClientEngineConfig
import io.ktor.client.engine.HttpClientEngineFactory
import io.ktor.client.plugins.contentnegotiation.ContentNegotiation
import io.ktor.client.plugins.cookies.HttpCookies
import io.ktor.client.plugins.logging.LogLevel
import io.ktor.client.plugins.logging.Logging
import io.ktor.serialization.kotlinx.json.json
import kotlinx.coroutines.Dispatchers
import kotlinx.coroutines.MainScope
import kotlinx.coroutines.invoke
import org.kodein.di.DI
import org.kodein.di.bind
import org.kodein.di.instance
import org.kodein.di.singleton
import three.two.bit.client.manager.UserManager
import three.two.bit.client.manager.UserManagerImpl
import three.two.bit.client.repository.UserRemoteRepository
import three.two.bit.client.repository.UserRemoteRepositoryImpl
import three.two.bit.shared.di.SharedModule

object ClientMainModule {

    val api = DI.Module(name = "API") {
        import(SharedModule.shared)
        import(ClientMppModule.api)
        bind(SERVER_URL) { singleton { "https://i.rlt.sk" } }
        bind<UserRemoteRepository> { singleton { UserRemoteRepositoryImpl(di) } }
        bind<UserManager> { singleton { UserManagerImpl(di) } }
        bind { singleton { MainScope() } }
//        bind { singleton { Dispatchers.Default } }
        bind { singleton { Dispatchers.Main } }
        bind {
            singleton {
                val engineFactory: HttpClientEngineFactory<HttpClientEngineConfig> = instance()
                HttpClient(engineFactory) {
                    install(HttpCookies)

                    install(Logging) {
                        this.level = LogLevel.ALL
                    }
                    install(ContentNegotiation) {
                        json()
                    }
                }
            }
        }

    }

    const val SERVER_URL = "SERVER_URL"
}
